
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.join-our-server-text {
  color: #FEFFFA;
  font-size: 2.5vh;
  text-decoration: none;
}

.faq-text {
  color: #FEFFFA;
  font-size: 4vh;
  text-decoration: none;
}

.add-bot-button {
  background-color: #6DFAD5;
  border-radius: 0.5em;
  border: #1A1E28;
  color: #1A1E28;
  cursor: pointer;
  font-size: 4vh;
  font-weight: 600;
  padding: 0.7em 1.5em;

}

.landing-big-text{
  color: #FEFFFA;
  font-size: 8vh;
  align-self: center;
  text-align: center;
  text-decoration: none;
}
.landing-body {
  background-color: #1A1E28;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-button {
  background-color: #FEFFFA;
  cursor: pointer;
  margin-right: .5vh;
  font-size: 2vh;
  font-weight: 600;

  border-radius: 10%;
  color: #1A1E28;
  border: #1A1E28;
  text-decoration: none;

}
.nodec-buttons-text {
  color:#1A1E28;
}
.nomarkers{
  text-decoration: none;
}
.nomarkers::visited {
  text-decoration: none;
  color: #1A1E28;
}
.signup-button {
  background-color: #6DFAD5;
  cursor: pointer;
  font-size: 2vh;
  border-radius: 10%;
  font-weight: 600;
  color:#1A1E28;
  border: #1A1E28;

}

.login-buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bomb-logo {
  size: 50%;
  position: absolute;
  bottom:10px;
  left:10px;
}
.top-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.text-logo {
  size: 50%;
  margin-right: 140vh;
}
.App-header {
  min-height: 10vh;
  background-color: #1A1E28;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media only screen and (max-width: 768px) {
  .text-logo {
    size: 50%;
    margin-right: 0vh;
  }
  .top-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}